/* Reset all elements' default padding and margin to zero */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    --foreground-rgb: 0, 0, 0;
    --background-start-rgb: 214, 219, 220;
    --background-end-rgb: 255, 255, 255;

    /* Color palette */
    --background-dark: #041326;
    --background-dark-98: rgba(4, 19, 38, 0.98);
    --background-dark-90: rgba(4, 19, 38, 0.9);
    --background-dark-hover: hsl(214, 71%, 16%);
    --background-light: #ffffff;
    --text-light: #ffffff;
    --text-dark: #041326;
    --light-grey: #b3b9bd;
    --mid-grey: #98a1a6;
    --mid-blue: #0c2840;

    /* Typefaces */
    --font-sans: "Inter", sans-serif;

    /* Spacing */
    --padding-small: 0.5rem;
    --padding-medium: 0.75rem;
    --padding-large: 1.25rem;

    --margin-small: 0.75rem;
    --margin-medium: 1.25rem;
    --margin-large: 2rem;
}

/* Font styles */
.title {
    font-size: 1.1rem;
    font-weight: 700;
    letter-spacing: 0.4rem;
    color: var(--text-light);
    margin-bottom: 0.2rem;
}
.sub-title {
    font-size: 0.8rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
}

h1 {
    font-size: 2rem;
    line-height: 1.2;
}

h2 {
    font-size: 1.75rem;
    line-height: 1.3;
}

h3 {
    font-size: 1.5rem;
    line-height: 1.4;
}

h4 {
    font-size: 1.25rem;
    line-height: 1.5;
}

h5 {
    font-size: 1.1rem;
    line-height: 1.6;
}

h6 {
    font-size: 1rem;
    line-height: 1.7;
}

.title {
    font-size: 3rem;
}

.sub-title {
    font-size: 2.1rem;
}

p,
.link-text {
    font-size: 1rem;
    line-height: 1.6;
}

.underline-text {
    text-decoration: underline;
}

.text-large {
    font-size: 2.1rem;
}

.text-thin {
    font-size: 1.2rem;
    font-weight: lighter;
    color: var(--light-grey);
}
/* Padding Classes */
.pt-1 {
    padding-top: var(--padding-small);
}

.pt-2 {
    padding-top: var(--padding-medium);
}

.pt-3 {
    padding-top: var(--padding-large);
}

.pb-1 {
    padding-bottom: var(--padding-small);
}

.pb-2 {
    padding-bottom: var(--padding-medium);
}

.pb-3 {
    padding-bottom: var(--padding-large);
}

.pl-1 {
    padding-left: var(--padding-small);
}

.pl-2 {
    padding-left: var(--padding-medium);
}

.pl-3 {
    padding-left: var(--padding-large);
}

.pr-1 {
    padding-right: var(--padding-small);
}

.pr-2 {
    padding-right: var(--padding-medium);
}

.pr-3 {
    padding-right: var(--padding-large);
}

/* Margin Classes */
.mb-1 {
    margin-bottom: var(--margin-small);
}

.mb-2 {
    margin-bottom: var(--margin-medium);
}

.mb-3 {
    margin-bottom: var(--margin-large);
}

.mt-1 {
    margin-top: var(--margin-small);
}

.mt-2 {
    margin-top: var(--margin-medium);
}

.mt-3 {
    margin-top: var(--margin-large);
}

.ml-1 {
    margin-left: var(--margin-small);
}

.ml-2 {
    margin-left: var(--margin-medium);
}

.ml-3 {
    margin-left: var(--margin-large);
}

.mr-1 {
    margin-right: var(--margin-small);
}

.mr-2 {
    margin-right: var(--margin-medium);
}

.mr-3 {
    margin-right: var(--margin-large);
}

/* Hamburger menu styles */
ul {
    list-style-type: none;
    padding: 0;
}

.dropdown-menu li {
    padding-top: var(--padding-medium);
    padding-bottom: var(--padding-medium);
}

.mobile-menu li ul {
    padding-left: 1rem;
}

.mobile-menu li ul li {
    padding: 0.5rem 0;
}

.mobile-menu a {
    color: var(--text-light);
    text-decoration: none;
    display: block;
    padding: 0.5rem 0;
}

/* Color Styles */
.text-link.text-dark:hover {
    color: var(--text-dark);
}

a {
    color: var(--text-light);
}

a:hover {
    color: var(--light-grey);
}

/* Font Styles */
body {
    font-family: var(--font-sans);
    color: var(--text-light);
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

p > .text-large {
    font-size: 1.7rem;
}

.copyright {
    font-size: 1.4rem;
    color: var(--mid-grey);
}

.uppercase {
    text-transform: uppercase;
}

/* Button Styles */
.btn-primary {
    padding: 1.2rem 3.6rem;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.2rem;
    border-radius: 2px;
    background-color: var(--background-dark-90);
    max-width: fit-content;
}
.btn-primary:hover {
    background-color: var(--background-dark-hover);
}
.btn-secondary {
    padding: 1.2rem 4rem;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.1rem;
    border-radius: 2px;
    background-color: var(--light-grey);
    width: fit-content;
    color: var(--text-dark);
}

.btn-tertiary {
    padding: 1.2rem 4rem;
    font-size: 16px;
    border: 1px solid var(--mid-grey);
    border-radius: 2px;
    background-color: var(--background-dark);
    width: fit-content;
    text-align: center;
}
.btn-tertiary:hover {
    background-color: var(--background-dark-hover);
}

button:hover {
    cursor: pointer;
}

/* Custom Form Container */
.custom-form-container {
    width: 100%;
    margin: 0 auto;
    margin-top: 3rem;
}

/* Flex Styles */
.flex-container {
    display: flex;
    flex-direction: row;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.wrap {
    flex-wrap: wrap;
}

.flex-col-center {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.align-center {
    justify-content: center;
}

.align-vertical-middle {
    align-items: center;
}

.align-vertical-btm {
    align-items: end;
}

.space-between {
    align-content: space-between;
}

.space-between-col {
    justify-content: space-between;
}
.hero-content-container .align-start {
    align-items: start;
}

.flex-1 {
    flex-grow: 1;
}

.width-30 {
    flex-basis: 30%;
}

.width-70 {
    flex-basis: 70%;
}

/* Custom Element Styles */
header {
    background-color: var(--background-dark);
    max-width: 100%;
}

.nav-link {
    margin-right: 1.2rem;
}

.nav-link:last-child {
    margin-right: 0;
}

.hero-content-position {
    position: absolute;
    top: 40%;
    left: 5%;
    z-index: 1;
    max-width: 100%;
}

.hero-content-container {
    height: auto;
    position: absolute;
    left: 5%;
    top: 1%;
}

.footer {
    display: flex;
    flex-direction: column;
    flex-flow: row-reverse;
    flex-wrap: wrap;
    justify-content: start;
}

.footer-logo {
    width: 80%;
    height: auto;
}

.footer-link {
    font-size: 1rem;
    padding-top: 0.75rem;
}

.footer-heading {
    font-size: 0.9rem;
    color: var(--light-grey);
}

.footer-text {
    font-size: 1.1rem;
}

.footer-divider {
    border-top: 1px solid var(--mid-grey);
}

.footer-col-1 {
    flex-basis: fill;
}

.site-map {
    width: 100%;
    flex-basis: fill;
    margin-left: 0;
}

.exhibitor-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.exhibitor-inner-container {
    display: flex;
    flex-direction: column;
}

.exhibitor-inner-container a {
    align-self: center;
}

.exhibitor-container h3 {
    font-size: 1.75rem;
    font-weight: 500;
}

.exhibitor-container a {
    color: var(--text-dark);
    font-size: 1.25rem;
    font-weight: 500;
}
.AppHeader {
    background-color: var(--background-dark);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(5px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

/* ------ FORM STYLES ------ */
.form {
    padding: 20px;
}

.form-body {
    text-align: left;
    padding: 20px 10px;
}

.form-body > * {
    padding: 5px;
}

.form__label {
    width: 40%;
}

.form_input {
    width: 60%;
}

.form__label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
}

.form__input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.form__textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    resize: vertical;
}

.submit-button {
    padding: 15px;
    width: 100%;
    background-color: #4caf50;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.submit-button:hover {
    background-color: #45a049;
}

.alert-message {
    margin-top: 20px;
}

.registration-form {
    max-width: 95%;
    margin: auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* ------ END OF FORM STYLES  ------ */

/* ====== CARD STYLES ======= */
/* Card styling */
.card-container {
    background-color: var(--background-dark);
    border-radius: 4px;
    margin: var(--margin-small);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;

    transition: transform 0.2s; /* adds a short delay in effect */
}

.card {
    flex-basis: 100%; /* Default for smaller screens */
    flex-grow: 1;
}

.img-fluid {
    max-width: 100%;
    height: auto;
    display: block;
}

.card-heading {
    font-size: 1.2rem;
    line-height: inherit;
    margin: var(--margin-small) 0 0 0;
    text-transform: uppercase;
}

.card-description {
    font-size: 1.2rem;
    color: var(--text-light);
    text-align: left;
}

/* Event Container */
.event-container {
    padding: 2% 5%;
}

.event-title {
    text-align: center;
}

.timeline {
    margin-top: 20px;
}

.timeline-item {
    position: relative;
    padding: 10%;
    border-left: 2px solid #98a1a6;
    margin-bottom: 20px;
}

.timeline-date {
    position: absolute;
    left: -50px;
    top: 50%;
    transform: translateY(-50%);
    background-color: #041326;
    color: #ffffff;
    padding: 8px;
    border-radius: 4px;
}

.timeline-content {
    margin-left: 20px;
}

/* Changes H1 depending on screen size */
.short-text {
    display: inline-block;
}

.full-text {
    display: none;
}

@media screen and (min-width: 600px) {
    .btn-primary,
    .btn-secondary,
    .btn-tertiary {
        padding: 1.2rem 4rem;
        font-size: 18px;
    }
    .hero-content-position {
        top: 20%;
    }
    .hero-content-container {
        width: 70%;
    }
    .hero-image-container {
        max-height: 100vh;
    }
    .title {
        font-size: 3rem;
        letter-spacing: 0.25rem;
    }
    .sub-title {
        font-size: 1.6rem;
        margin-bottom: var(--margin-medium);
    }
    h4 {
        font-size: rem;
    }
    .avconXtra-position {
        order: 2;
        margin-bottom: var(--margin-large);
    }
}

/* ========= TABLET ========= */
@media screen and (max-width: 768px) {
    .event-container {
        padding: 2% 3%;
    }

    .timeline-date {
        left: 0;
        top: 0;
        transform: none;
        margin-bottom: 10px;
    }

    .timeline-content {
        margin-left: 0;
    }
}

/* ========= LARGE TABLET, NOTEBOOKS, SM LAPTOPS ========= */
@media screen and (min-width: 768px) {
    :root {
        --padding-small: 0.5rem;
        --padding-medium: 1rem;
        --padding-large: 1.25rem;

        --margin-small: 0.5rem;
        --margin-medium: 1rem;
        --margin-large: 1.5rem;
    }
    .full-text {
        display: inline-block;
    }
    .short-text {
        display: none;
    }
    .hero-content-position {
        position: absolute;

        width: 90%;
    }

    .title {
        font-size: 2.8rem;
        letter-spacing: 0.3rem;
        margin-bottom: var(--margin-small);
    }
    .sub-title {
        font-size: 1.8rem;
        margin-bottom: var(--margin-small);
    }
    .card {
        gap: 2.5rem;
        flex-basis: calc(40% - 1rem);
        flex-grow: 1;
        /* Two cards per row, with space between */
    }
    .btn-primary {
        font-size: 1.2rem;
    }
    .btn-tertiary {
        padding: var(--padding-medium) var(--padding-large);
        font-size: 1rem;
    }

    h1 {
        font-size: 2.5rem;
    }

    h2 {
        font-size: 2.25rem;
    }

    h3 {
        font-size: 2rem;
    }

    h4 {
        font-size: 1.75rem;
    }

    h5 {
        font-size: 1.5rem;
    }

    h6 {
        font-size: 1.25rem;
    }

    p,
    .link-text {
        font-size: 0.75rem;
    }

    .custom-form-container {
        width: 100%; /* Set width to 50% */
        margin: 0 auto; /* Center the form */
        margin-top: 5rem;
    }
    .card-container {
        gap: 3rem;
    }

    .card-heading {
        font-size: 1.5rem;
    }

    .footer {
        display: flex;
        flex-direction: row;
        flex-flow: row-reverse;
        justify-content: start;
    }
    .footer-logo {
        width: 65%;
        height: auto;
    }
    .footer-divider {
        border-right: 1px solid var(--mid-grey);
        border-top: none;
    }
    .footer-col-1 {
        flex-basis: 35%;
    }
    .site-map {
        flex-basis: 65%;
    }
    .position {
        order: 2;
    }

    .exhibitor-container {
        display: flex;
        flex-direction: row;
        align-items: start;
    }
    .exhibitor-inner-container {
        display: flex;
        flex-direction: column;
    }
    .exhibitor-container h3 {
        font-size: 2.2rem;
        font-weight: 600;
        flex-basis: fill;
    }
    .exhibitor-container h4 {
        font-size: 1.7rem;
        font-weight: 600;
    }
    .exhibitor-container a {
        /* color: var(--text-dark); */
        font-size: 1.5rem;
        font-weight: 600;
        align-self: start;
    }
    .site-map {
        margin-left: var(--margin-large);
    }
}

/* ========= LARGE LAPTOPS  ========= */
@media screen and (min-width: 992px) {
    :root {
        --padding-small: 0.75rem;
        --padding-medium: 1.25rem;
        --padding-large: 1.5rem;

        --margin-small: 0.75rem;
        --margin-medium: 1.25rem;
        --margin-large: 1.5rem;
    }

    .card-container {
        gap: 3rem;
    }
    .card {
        flex-basis: calc(40% - 1rem);
        flex-grow: 1;
    }
    .title {
        letter-spacing: 0.5rem;
    }
    .full-text {
        font-size: 2.2rem;
    }
    .sub-title {
        font-size: 1.5rem;
        margin-bottom: var(--margin-small);
    }
    .btn-primary {
        padding: var(--padding-medium) 4rem;
        font-size: 1.4rem;
    }
    h1 {
        font-size: 3rem;
    }

    h2 {
        font-size: 2.75rem;
    }

    h3 {
        font-size: 2.5rem;
    }

    h4 {
        font-size: 2rem;
    }

    h5 {
        font-size: 1.75rem;
    }

    h6 {
        font-size: 1.5rem;
    }

    p {
        font-size: 1rem;
    }
    .link-text {
        font-size: 0.8rem;
    }
    .underline-text {
        text-decoration: underline;
    }
    .custom-form-container {
        width: 85%; /* Set width to 50% */
        margin: 0 auto; /* Center the form */
        margin-top: 5rem;
    }
    .footer-col-1 {
        flex-basis: 35%;
    }
    .site-map {
        flex-basis: 65%;
    }
    .avconXtra-container .position {
        order: 1;
    }
}
/* ========= DESKTOPS, 1024px and up ========= */
@media screen and (min-width: 1024px) {
    :root {
        --padding-small: 1rem;
        --padding-medium: 1.5rem;
        --padding-large: 2rem;

        --margin-small: 1rem;
        --margin-medium: 1.5rem;
        --margin-large: 2rem;
    }

    .container header .header-nav-area #nav_container,
    .custom-form-container {
        width: 95%; /* Set width to 95 of viewport% */
        margin: 0 auto; /* Center the form */
        margin-top: 6rem;
    }

    /* Target the actual injected form */
    .custom-form-container form {
        width: 100%; /* Make sure the form inside takes the full width of the container */
    }
    .hero-content-position {
        width: 80%;
        top: 15%;
    }
    .title {
        font-size: 3.6rem;
        letter-spacing: 0.4rem;
    }
    .sub-title {
        font-size: 2rem;
        margin-bottom: var(--margin-large);
    }
    .card-container {
        gap: 2rem;
    }

    .card {
        flex-basis: calc(30% - 0.5rem); /* Default for smaller screens */
        flex-grow: 1;
    }
    .card-heading {
        line-height: 1.7rem;
    }
}

/* ========= EXTRA LARGE DEVICES (LARGE DESKTOPS, 1200px and up) ========= */
@media screen and (min-width: 1200px) {
    :root {
        --padding-small: 1.25rem;
        --padding-medium: 1.75rem;
        --padding-large: 2.5rem;

        --margin-small: 1.25rem;
        --margin-medium: 1.75rem;
        --margin-large: 2.5rem;
    }
    .hero-content-container {
        width: 80%;
    }
    .title {
        font-size: 3.6rem;
        letter-spacing: 0.4rem;
    }
    .sub-title {
        font-size: 2rem;
        margin-bottom: var(--margin-small);
    }
    .btn-primary {
        font-size: 1.6em;
        letter-spacing: 0.4rem;
    }
    .btn-tertiary {
        padding: var(--padding-small) 3rem;
        font-size: 2rem;
    }
    h1 {
        font-size: 3.5rem;
    }

    h2 {
        font-size: 3.25rem;
    }

    h3 {
        font-size: 2.5rem;
    }

    h4 {
        font-size: 2.25rem;
    }

    h5 {
        font-size: 1.6rem;
    }

    h6 {
        font-size: 1.2rem;
    }

    p {
        font-size: 1.375rem;
    }
    .link-text {
        font-size: 1rem;
    }
    .underline-text {
        text-decoration: underline;
    }
    .custom-form-container {
        width: 95%;
        margin: 0 auto;
        margin-top: 6rem;
    }

    .card {
        flex-basis: calc(30% - 2rem);
    }
}

@media screen and (min-width: 1600px) {
    .link-text {
        font-size: 1.5rem;
        font-weight: 400;
    }

    /* :has = will select the container if it is a long title and not a short title */
    .hero-content-container:has(.title-long) {
        max-width: 75%;
    }

    .hero-content-position {
        width: 85%;
        top: 20%;
    }
    .title {
        font-size: 4rem;
        letter-spacing: 0.4rem;
    }
    .sub-title {
        font-size: 2rem;
        margin-bottom: var(--margin-large);
    }
    .link-text {
        font-size: 1.2rem;
    }
    .btn-primary,
    .btn-tertiary {
        padding: 1.8rem 6rem;
        font-size: 1.8rem;
    }
}
